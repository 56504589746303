import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import * as XLSX from "xlsx";
import { useAddBulkQuestionMutation } from "../../redux/QuestionsAPISlice";

const AddBulkQuestion = () => {
  const { topic } = useLocation().state;
  const [
    addBulkQuestionMutation,
    { isError, error: bulkQuestionError, isSuccess, isLoading },
  ] = useAddBulkQuestionMutation();
  const [totalQuestions, setTotalQuestions] = useState(null);
  const [questionsData, setQuestionsData] = useState(null);

  const handleFileImport = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const formattedData = processExcelData(json);
      setTotalQuestions(formattedData.length); // Set the total questions count
      setQuestionsData(formattedData); // Store the formatted data for later upload
    };
    reader.readAsArrayBuffer(file);
  };

  const processExcelData = (data) => {
    const rows = data.slice(1).filter(row => row.some(cell => cell !== undefined && cell !== ''));

    return rows.map((row) => {

      const [
        question,
        optionA,
        optionB,
        optionC,
        optionD,
        correctAnswer,
        solutionDescription,
      ] = row;

    

      const choices = [
        { choice: optionA, rightChoice: correctAnswer.toLowerCase() === "a" },
        { choice: optionB, rightChoice: correctAnswer.toLowerCase() === "b" },
        { choice: optionC, rightChoice: correctAnswer.toLowerCase() === "c" },
        { choice: optionD, rightChoice: correctAnswer.toLowerCase() === "d" },
      ];

      return {
        question,
        choices,
        solutionDescription,
      };
    });
  };

  const handleUploadToServer = () => {
    if (questionsData) {
      addBulkQuestionMutation({ topicId: topic.id, body: questionsData });
    }
  };

  const handleDownloadTemplate = () => {
    // Template data
    const templateData = [
      [
        "Question",
        "Option A",
        "Option B",
        "Option C",
        "Option D",
        "Correct Answer",
        "Solution",
      ],
      [
        "What is the capital of France?",
        "Paris",
        "London",
        "Berlin",
        "Rome",
        "A",
        "Paris is the capital of France.",
      ],
      ["What is 2 + 2?", "3", "4", "5", "6", "B", "Simple arithmetic."],
    ];

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(templateData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    // Generate the Excel file and trigger download
    XLSX.writeFile(workbook, "Bulk_Questions_Template.xlsx");
  };

  return (
    <div>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        {topic.title}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Button
          variant="contained"
          onClick={handleDownloadTemplate}
          sx={{ marginRight: 2 }}
        >
          Download Template
        </Button>
        <Button variant="contained" component="label" disabled={isLoading}>
          {isLoading ? "Uploading..." : "Import Excel File"}
          <input type="file" hidden onChange={handleFileImport} />
        </Button>
      </Box>
      {totalQuestions !== null && (
        <>
          <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
            Total Questions in Excel: {totalQuestions}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={handleUploadToServer}
              disabled={isLoading || !questionsData}
            >
              {isLoading ? "Uploading..." : "Upload to Server"}
            </Button>
          </Box>
        </>
      )}
      {isError && (
        <Typography color="error" sx={{ textAlign: "center", marginTop: 2 }}>
          {bulkQuestionError.message}
        </Typography>
      )}
      {isSuccess && (
        <Typography color="success" sx={{ textAlign: "center", marginTop: 2 }}>
          Questions uploaded successfully!
        </Typography>
      )}
    </div>
  );
};

export default AddBulkQuestion;
